import React, { useEffect, useState } from 'react';
import { getAccounts } from '../../libs/api';
import { useAuth } from '../AuthContext/AuthContext'

type Account = {
  uuid: string;
  shop_name: string;
};

type AccountSelectorProps = {
  onAccountSelect: (accountId: string | null) => void;
};

const AccountSelector: React.FC<AccountSelectorProps> = ({ onAccountSelect }) => {
  const { userProfile } = useAuth();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>("default"); // Option par défaut

  useEffect(() => {
    // Fetch accounts only if the user is admin
    if (userProfile?.is_admin) {
      const fetchAccounts = async () => {
        const data = await getAccounts();
        setAccounts(data);
      };

      fetchAccounts();
    }
  }, [userProfile]);

  const handleAccountChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const accountId = event.target.value === "default" ? null : event.target.value;
    setSelectedAccount(accountId);
    onAccountSelect(accountId);
  };

  // Render nothing if user is not admin
  if (!userProfile?.is_admin) {
    return null;
  }

  return (
    <div className="account-selector">
      <label htmlFor="account">Sélectionnez un compte :</label>
      <select id="account" value={selectedAccount || ''} onChange={handleAccountChange}>
        <option value="default" disabled>
          -- Sélectionnez un compte --
        </option>
        {accounts.map((account) => (
          <option key={account.uuid} value={account.uuid}>
            {account.shop_name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AccountSelector;
