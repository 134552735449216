// CheckoutsPage.tsx
import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Checkouts.css';
import { getCheckouts, getCheckoutsByAccountId, syncCheckouts } from '../libs/api'
import Menu from '../components/Menu/Menu';
import { startCall } from '../libs/api';
import { Checkout } from '../types/Checkout';
import { useAuth } from '../components/AuthContext/AuthContext';
import AccountSelector from '../components/AccountSelector/AccountSelector';
import Table from '../components/Table/Table';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || '';
const supabase = createClient(supabaseUrl, supabaseKey);

function CheckoutsPage() {
  const [checkouts, setCheckouts] = useState<Checkout[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { userProfile } = useAuth();

  useEffect(() => {
    if (selectedAccount) {
      fetchCheckoutsForAccount(selectedAccount);
    }
  }, [selectedAccount]);

  const fetchCheckoutsForAccount = async (accountId: string) => {
    const data = await getCheckoutsByAccountId(accountId);
    setCheckouts(data);
  };

  useEffect(() => {
    const fetchCheckouts = async () => {
      const data: Checkout[] = await getCheckouts();
      setCheckouts(data);
    };

    fetchCheckouts();

    const subscription = supabase
      .channel('marian_checkouts')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'marian_checkouts' }, payload => {
        console.log('Changement détecté:', payload);
        fetchCheckouts();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  const handleCall = async (checkoutUuid: string) => {
    try {
      await startCall(checkoutUuid);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de l\'appel.');
    }
  };

  const sync = async () => {
    try {
      setLoading(true);
      await syncCheckouts();
      setLoading(false);
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de la synchro.');
    }
  };

  const headers = [
    { key: 'shop_name', label: 'Boutique', render: (_: any, row: Checkout) => row.marian_accounts.shop_name },
    { key: 'customer_name', label: 'Nom du client' },
    { key: 'checkout_created_at', label: 'Date du panier' },
    { key: 'total_price', label: 'Montant du panier', width: '15%' },
    { key: 'call_status', label: 'Status de l\'appel', width: '15%' },
    {
      key: 'call_date',
      label: 'Date de l\'appel',
      render: (_: any, row: Checkout) =>
        row.call_status !== 'call_not_started' ? (
          <div>début: {row.call_started_at}
            <br />
            fin: {row.call_finished_at}
          </div>
        ) : null,
    },
    {
      key: 'actions',
      label: 'Action',
      render: (_: any, row: Checkout) =>
        row.call_status === 'call_not_started' ? (
          <button onClick={() => handleCall(row.uuid)}>Call</button>
        ) : null,
    },
    {
      key: 'mp3',
      label: 'MP3',
      render: (_: any, row: Checkout) =>
        row.call_status === 'call_finished' && row.twilio_call_recording_url ? (
          <audio controls src={row.twilio_call_recording_url}></audio>
        ) : null,
    },
  ];

  const total = checkouts.reduce((acc, checkout) => acc + parseFloat(checkout.total_price), 0);

  return (
    <div className="container">
      <Menu></Menu>
      <div className="main-content">
        <div className="content-datas">
          {userProfile?.is_admin && (
            <>
              <AccountSelector onAccountSelect={setSelectedAccount} />
              <button onClick={sync}>Sync {loading && 'loading...'}</button>
            </>
          )}
          <Table headers={headers} data={checkouts} />
        </div>
        <div className="summary">
          <span>Total des montants de paniers: {total.toFixed(2)}</span>
          <br />
          <span>Potentiel pour Marian: {((total * 15) / 100).toFixed(2)}€</span>
        </div>
      </div>
    </div>
  );
}

export default CheckoutsPage;
