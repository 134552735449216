import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Accounts.css';
import { getAccounts } from '../libs/supabase'
import Menu from '../components/Menu/Menu';
import { Account } from '../types/Account';
import Table from '../components/Table/Table';

// Initialisation de Supabase
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || '';
const supabase = createClient(supabaseUrl, supabaseKey);

function AccountsPage(): JSX.Element {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      const data: any = await getAccounts()

      setAccounts(data);
    };

    fetchAccounts();

    // Mise en place de la souscription pour les mises à jour en temps réel
    const subscription = supabase
      .channel('marian_accounts') // Canal realtime
      .on('postgres_changes', { event: '*', schema: 'public', table: 'marian_accounts' }, payload => {
        console.log('Changement détecté:', payload);
        fetchAccounts(); // Recharge les accounts en cas de changement
      })
      .subscribe();

    // Nettoyage de la souscription lors de la déconnexion
    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  const headers = [
    { key: 'shop_name', label: 'Boutique' },
    { key: 'shopify_domain', label: 'Domain' },
    { key: 'shop_currency', label: 'Devise' },
    { key: 'app_installed_at', label: 'Date d\'installation' },
    { key: 'app_uninstalled_at', label: 'Date de désinstallation' },
    { key: 'shop_owner_name', label: 'Propriétaire', render: (_: any, row: Account) => (<div>{row.shop_owner} ({row.shop_owner_email})</div>) },
    { key: 'shopify_discount_name', label: 'Discount', render: (_: any, row: Account) => (<div>{row.shopify_discount_name} ({row.shopify_discount_value})</div>) },
    { key: 'voice_tone_description', label: 'Voix', render: (_: any, row: Account) => (<div>{row.voice_tone_description} ({row.voice_name})</div>) }
  ];

  return (
    <div className="container">
      <Menu></Menu>
      <div className="main-content">
        <Table headers={headers} data={accounts} />
      </div>
    </div>
  );
}

export default AccountsPage;
