import { axiosInstance } from "./axios-instance";

export const auth = async (email: string, password: string, action: string) => {
  try {
    const response = await axiosInstance.post(`/auth`, {
      email,
      password,
      action,
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error during authentification: ${error}`);
  }
};

export const logout = async () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    await axiosInstance.post(`/auth`, {
      action: "logout",
    });
  } catch (error) {
    // throw new Error(`Error during logout: ${error}`);
  }
};

export const refreshAuthToken = async () => {
  try {
    const response = await axiosInstance.post(`/auth`, {
      action: "refresh_token",
      refresh_token: localStorage.getItem("refresh_token"),
    });
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("refresh_token", response.data.refresh_token);
    return true;
  } catch (error) {
    // throw new Error(`Error during logout: ${error}`);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    return false;
  }
};

export const getProfile = async () => {
  try {
    const response = await axiosInstance.post(`/auth`, {
      action: "profile",
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error during logout: ${error}`);
  }
};

export const updateUserProfile = async (data: any) => {
  try {
    const response = await axiosInstance.put(`/profile`, {
      ...data,
    });

    return response.data;
  } catch (error) {
    throw new Error(`Error during logout: ${error}`);
  }
};
