import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import Menu from '../components/Menu/Menu';
import { useAuth } from '../components/AuthContext/AuthContext';
import AccountSelector from '../components/AccountSelector/AccountSelector';
import { getDashboardDatas } from '../libs/api';

function DashboardPage() {
    const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
    const [data, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const { userProfile } = useAuth()

    useEffect(() => {
        if (selectedAccount) {
            fetchDashboardDatas(selectedAccount)
        }
    }, [selectedAccount]);

    const fetchDashboardDatas = async (selectedAccount: string) => {
        setLoading(true)
        const datas = await getDashboardDatas(selectedAccount);
        console.log(datas)
        setData(datas)
        setLoading(false)
    }

    return (
      <div className='container'>
        <Menu></Menu>
            <div className="main-content">
            {userProfile?.is_admin &&
            <>
              <AccountSelector onAccountSelect={setSelectedAccount} />
              <div>{loading && 'loading...'}</div>
            </>
          }
            <div className="dashboard">
                <h1>Performance Dashboard</h1>
                    {data && 
                        <>
                    <div className="stats-cards">
                        <div className="stat-card">
                        <h3>Total Calls</h3>
                        <p>{data.totalCalls}</p>
                        </div>
                        <div className="stat-card">
                        <h3>Converted Carts</h3>
                        <p>{data.convertedCarts}</p>
                        </div>
                        <div className="stat-card">
                        <h3>Revenue Generated</h3>
                        <p>€{data.revenueGenerated}</p>
                        </div>
                        <div className="stat-card">
                        <h3>Customer Satisfaction</h3>
                        <p>{data.customerSatisfaction} / 5</p>
                        </div>
                    </div>

                    <div className="detailed-stats">
                        <div className="chart">
                        <h3>Call Feedback Analysis</h3>
                        <ul>
                            <li>Positive: {data.feedbackStats.positive}</li>
                            <li>Neutral: {data.feedbackStats.neutral}</li>
                            <li>Negative: {data.feedbackStats.negative}</li>
                        </ul>
                        </div>
                        <div className="chart">
                        <h3>Recent Calls</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Feedback</th>
                                <th>Duration</th>
                                <th>Cart Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.recentCalls.map((call:any) => (
                                <tr key={call.id}>
                                <td>{call.status}</td>
                                <td>{call.date}</td>
                                <td>{call.feedback} / 5</td>
                                <td>{call.duration}</td>
                                <td>{call.cartValue}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                        </>
                }
            </div>
        </div>
    </div>
  );
}

export default DashboardPage;
