import React, { useState } from 'react';
import './Home.css'

function Home(): JSX.Element {
  const [formData, setFormData] = useState({
    firstName: '',
    companyName: '',
    phoneNumber: ''
  });
  const [result, setResult] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setResult('')
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_MARIAN_CALLER_URL}/engine/start-call`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (data.sid) {
        setResult('Appel lancé avec succès!');
      } else {
        setResult('Erreur lors de l\'appel.');
      }
    } catch (error) {
      console.error('Erreur:', error);
      setResult('Une erreur est survenue lors de l\'appel.');
    }
  };

  return (
    <div className="container">
      <div className="left">
        <h1>Marian.<span>tel</span></h1>
        <p>La première IA qui appelle vos paniers abandonnés pour les faire revenir et les convertir.</p>
        <p>Collectez des feedbacks sur votre expérience d'achat, comprenez ce qui a empêché vos visiteurs de convertir, identifiez vos points de friction pour augmenter votre taux de conversion et faites revenir les abandonistes pour générer plus de chiffre d'affaires dès aujourd'hui.</p>
        <p><strong>Spécialement conçu pour Shopify.</strong></p>
        <hr />
        <p>Testez notre expérience gratuitement : indiquez votre prénom, le nom de votre entreprise et votre numéro téléphone dans le formulaire à droite de votre écran.

Notre IA va vous appeler en faisant comme si vous n’étiez pas allé jusqu’au bout de votre achat et vous demander pourquoi. Elle va ensuite vous proposer un coupon pour vous inciter à revenir sur le site. C’est ce que vivrons vos visiteurs abandonnistes lorsque vous aurez connecté Marian à votre boutique Shopify !</p>
        <p><em>Pourquoi “Marian” ? C’est un hommage à Marian Croak, ingénieure américaine ayant inventé la VOIP, la technologie que l’on utilise pour appeler vos visiteurs et vous générer plus de ventes !</em></p>
      </div>
      <div className="right">
        <form onSubmit={handleSubmit}>
        <label>
        Prénom
          <input
            type="text"
            name="firstName"
            placeholder="Prénom"
            value={formData.firstName}
            onChange={handleChange}
            required
            style={{ marginBottom: '15px', padding: '10px', width: '100%' }}
            />
            </label>
            <label>
            Nom de votre boutique
          <input
            type="text"
            name="companyName"
            placeholder="Nom de votre boutique"
            value={formData.companyName}
            onChange={handleChange}
            required
            style={{ marginBottom: '15px', padding: '10px', width: '100%' }}
              />
              </label>
              <label>
            Numéro de téléphone (+33)
            <input
              type="tel"
              name="phoneNumber"
              placeholder="+33610111213"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              style={{ marginBottom: '15px', padding: '10px', width: '100%' }}
            />
                </label>
          <button type="submit" style={{ padding: '15px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
            Me rappeler
          </button>
          <div id="result">{result}</div>
        </form>
      </div>
    </div>
  );
}

export default Home;
