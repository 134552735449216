import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProfile, refreshAuthToken } from '../../libs/auth-users';
import { isTokenExpiring } from '../../libs/jwt';

// Typage pour le contexte de l'utilisateur
type UserProfile = {
  uuid: string;
  username: string;
  email: string;
  is_admin: boolean;
  // Ajoutez d'autres informations utilisateur nécessaires ici
};

type AuthContextType = {
  isAuthenticated: boolean;
  userProfile: UserProfile | null;
  setIsAuthenticated: (value: boolean) => void;
  setUserProfile: (profile: UserProfile) => void;
};

// Typage des props pour le fournisseur
type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const navigate = useNavigate();

  const fetchUserProfile = async (token: string) => {
    try {
      if (isTokenExpiring(token)) {
        const r = await refreshAuthToken()
        if (!r) {
          setIsAuthenticated(false);
          localStorage.removeItem('token');
          localStorage.removeItem('refresh_token');
          navigate('/auth')
        } 
      }

      const response = await getProfile();

      if (response?.profile) {
        setUserProfile(response?.profile);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
        
    if (token) {
      setIsAuthenticated(true);
      fetchUserProfile(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userProfile,
        setIsAuthenticated,
        setUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
