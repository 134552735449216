import { createClient } from "@supabase/supabase-js";
const supabase = createClient(
  process.env.SUPABASE_URL || "https://zmymkyivpschgffvecqq.supabase.co",
  process.env.SUPABASE_KEY ||
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpteW1reWl2cHNjaGdmZnZlY3FxIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyNzI2OTM2MCwiZXhwIjoyMDQyODQ1MzYwfQ.13M_J3ZCaPDnnCn1UHC7WzfH9kXv2DmUF9AUtV7kRHM"
);

export async function saveCheckout(
  checkoutId: string,
  status: string,
  transcription = null
) {
  const { data, error } = await supabase
    .from("marian_checkouts")
    .update([{ call_content: transcription, call_status: status }])
    .eq("uuid", checkoutId);

  if (error) {
    console.error("Erreur lors de l'enregistrement dans Supabase:", error);
  } else {
    console.log("Transcription enregistrée dans Supabase:", data);
  }
}

export async function getCheckout(checkoutUuid: string) {
  const { data, error } = await supabase
    .from("marian_checkouts")
    .select()
    .eq("uuid", checkoutUuid);

  if (error) {
    console.error("get checkout error:", error);
  } else {
    return data[0];
  }
}

export async function getAccounts() {
  const { data, error } = await supabase.from("marian_accounts").select();

  if (error) {
    console.error("get accounts error:", error);
  } else {
    return data;
  }
}
