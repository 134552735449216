import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Menu.css';
import { useAuth } from '../AuthContext/AuthContext';

function Menu() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const location = useLocation();
  const { userProfile } = useAuth();

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleMobileMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${isExpanded ? 'expanded' : 'hidden'}`}>
      <h1>{isCollapsed ? 'M' : 'Marian.tel'}</h1>
      <ul>
        <li>
          <NavLink to="/dashboard" className={location.pathname === '/dashboard' ? 'active' : ''}>
            <span className="icon">💰</span>
            <span className="text">Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/checkouts" className={location.pathname === '/checkouts' ? 'active' : ''}>
            <span className="icon">🛒</span>
            <span className="text">Checkouts / Calls</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/orders" className={location.pathname === '/orders' ? 'active' : ''}>
            <span className="icon">📦</span>
            <span className="text">Orders</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/account" className={location.pathname === '/account' ? 'active' : ''}>
            <span className="icon">🏠</span>
            <span className="text">Shop connected</span>
          </NavLink>
        </li>
      </ul>
      {userProfile?.is_admin &&
        <ul>
          <li><hr></hr></li>
          <li>
            <NavLink to="/accounts" className={location.pathname === '/accounts' ? 'active' : ''}>
              <span className="icon">📄</span>
              <span className="text">Accounts</span>
            </NavLink>
          </li>
        </ul>
      }
      <div className="toggle-btn" onClick={toggleMenu}>
        {isCollapsed ? '➕' : '➖'}
      </div>
      <div className="hamburger-btn" onClick={toggleMobileMenu}>
        ☰
      </div>
    </div>
  );
}

export default Menu;

