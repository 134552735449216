import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './Orders.css';
import { getOrders, getOrdersByAccountId, syncOrders } from '../libs/api'
import Menu from '../components/Menu/Menu';
import { Order } from '../types/Order';
import { useAuth } from '../components/AuthContext/AuthContext';
import AccountSelector from '../components/AccountSelector/AccountSelector';
import Table from '../components/Table/Table';

// Initialisation de Supabase
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || '';
const supabase = createClient(supabaseUrl, supabaseKey);

function OrdersPage() {
  const [orders, setOrders] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const { userProfile } = useAuth()
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedAccount) {
      fetchOrdersForAccount(selectedAccount);
    }
  }, [selectedAccount]);

  const fetchOrdersForAccount = async (accountId: string) => {
    const data = await getOrdersByAccountId(accountId);
    setOrders(data);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const data: any = await getOrders()

      setOrders(data);
    };

    fetchOrders();

    // Mise en place de la souscription pour les mises à jour en temps réel
    const subscription = supabase
      .channel('marian_orders') // Canal realtime
      .on('postgres_changes', { event: '*', schema: 'public', table: 'marian_orders' }, payload => {
        console.log('Changement détecté:', payload);
        fetchOrders(); // Recharge les orders en cas de changement
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  const sync = async () => {
    try {
      setLoading(true)
      await syncOrders()
      setLoading(false)
    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de la synchro.');
    }
  };
  const headers = [
    { key: 'customer_name', label: 'Nom du client', render: (_: any, row: Order) =>
      (<div>{row.customer_name}<br></br>{row.customer_phone}</div>)
    },
    { key: 'order_created_at', label: 'Date du panier' },
    { key: 'total_price', label: 'Montant du panier', width: '15%', render: (_: any, row: Order) =>
      (<div>{row.total_price}<br></br>{row.marian_accounts.shop_currency}</div>) },
    { key: 'discount_name', label: 'Discount code', width: '15%' }
  ];

  const total = orders.reduce((acc, order: Order) => acc + parseFloat(order.total_price), 0);

  return (
    <div className="container">
      <Menu></Menu>
      <div className="main-content">
        <div className="content-datas">
          {userProfile?.is_admin &&
            <>
              <AccountSelector onAccountSelect={setSelectedAccount} />
              <button onClick={() => sync()}>Sync {loading && 'loading...'}</button>
            </>
          }
          <Table headers={headers} data={orders} />
        </div>
        <div className="summary">
            <span>Total des montants de paniers: {total.toFixed(2)}</span>
            <br></br>
            <span>Part pour Marian: {((total*10)/100).toFixed(2)}€</span>
        </div>
      </div>
    </div>
  );
}

export default OrdersPage;
